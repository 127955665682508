.icon-button{
  color: #fff;
}

.menu-links {
  a:hover {
  transition: color .25s;
  color: #0A66C2;

    svg {
      transition: color .25s;
      color: #0A66C2;
    }
  }
  a.active{
    color: #1b1464;
    span{
      font-weight: bold;
    }
    svg {
      transition: color .25s;
      color: #1b1464 ;
    }
  }
}

.nav-social-links{
  a{
    margin: 0 8px;
    transition: color .25s;
  }
  a:hover{
    color: #24c0f0;
  }
}