:root {
  --viewportVW: calc(100vw / 375);
}
@media (min-width: 768px) {
  :root {
    --viewportVW: calc(100vw / 1488);
  }
}

body {
  min-width: 320px;
}

main {
  min-width: 320px;
  width: 100%;
}

.title-bar {
  background: #F6FDFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  margin: 0 auto 32px;
  padding: 20px;
}
@media (min-width: 768px) {
  .title-bar {
    min-height: 120px;
  }
}

#loading-box {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
}
#loading-box .bg-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  top: 0;
  left: 0;
}
#loading-box .indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  width: 50px;
  height: 50px;
  border: 1px solid #999;
  background: #000 url(./static/images/loading.gif) no-repeat center center;
  border-radius: 3px;
}

/* =================================
Modal General Styles
=================================== */
#info-modal {
  display: none;
  opacity: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: perspective(800px);
}

.info-modal-bg {
  background: rgba(0, 0, 0, 0.85);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.info-modal-content {
  position: absolute;
  left: 50%;
  top: 0;
  background: #fff;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  margin-left: -50vw;
  overflow-y: auto;
}
@media (min-width: 1024px) {
  .info-modal-content {
    top: 50%;
    height: auto;
  }
}

@media (min-width: 980px) {
  .info-modal-content {
    max-width: 980px;
    margin-left: -490px;
    max-height: 720px;
    margin-top: -360px;
  }
}
.info-modal-header {
  display: flex;
  background-color: #0A66C2;
  padding: 20px;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: bold;
  font-family: "Bebas Neue", cursive;
  height: 64px;
  align-items: center;
}
@media (min-width: 768px) {
  .info-modal-header {
    font-size: 28px;
  }
}

.info-modal-header h3 {
  color: #eee;
  font-size: 20px;
  letter-spacing: normal;
}

.info-modal-header .close {
  color: #eee;
}

.info-modal-close-btn {
  color: #fff;
  background-color: #1b1464;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  outline: none;
  width: 64px;
  height: 64px;
  z-index: 99999;
  transition: background-color 0.25s;
  cursor: pointer;
}

.info-modal-close-btn:hover, .info-modal-close-btn:active, .info-modal-close-btn:focus {
  background-color: #24c0f0;
}

.info-modal-body {
  padding: 20px;
}

.info-modal-body .profile-desc {
  overflow: auto;
  margin-right: -30px;
  margin-bottom: 20px;
}

/*
 ul.modal-social-links{
   list-style:none;
   margin:0;
   padding:0;
 }
 ul.modal-social-links li{
   display:block;
   margin-right:20px;
   margin-bottom:0;
   text-align:center;
   line-height:1;
   vertical-align:middle;
   overflow:visible;
 }
 ul.modal-social-links li:last-child{
   margin-right:0;
 }

 ul.modal-social-links > li > a {
 border-radius: 1px;
 display: block;
 font-size: 15px;
 line-height: 1.33;
 padding: 5px;
 text-align: center;
 width: 100%;
 max-width:300px;
 border: 1px solid transparent;
 cursor: pointer;
 vertical-align: middle;
 white-space: nowrap;
 background-color:#8c8b8c;
 text-transform:uppercase;
 margin:0 auto;
 }
 ul.modal-social-links > li > a:hover,
 ul.modal-social-links > li > a:active{
 text-decoration:none;
 }
*/
.title-bar > svg {
  width: 85% !important;
}
@media (min-width: 768px) {
  .title-bar > svg {
    width: min(var(--viewportVW) * 300, 300px) !important;
  }
}

.title-bar h1 {
  font-size: calc(var(--viewportVW) * 40);
}
@media (min-width: 768px) {
  .title-bar h1 {
    font-size: min(var(--viewportVW) * 48, 48px);
  }
}

.title-bar .site-name {
  display: block;
  color: #0A66C2;
  font-size: calc(var(--viewportVW) * 80);
  line-height: 0.7;
}
@media (min-width: 768px) {
  .title-bar .site-name {
    margin-top: min(var(--viewportVW) * 12, 12px);
    font-size: min(var(--viewportVW) * 180, 180px);
    line-height: 0.7;
  }
}

@media (max-width: 899px) {
  .page-container {
    padding-top: 56px;
  }
}/*# sourceMappingURL=App.css.map */