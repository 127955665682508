.privacy-policy-section {
  position: relative;
  background: #ECEFF1;
  margin-bottom: 64px;
  padding-bottom: 32px;
  border-bottom: 5px solid #1B1464;
}

.privacy-policy-section-title {
  padding: 30px 20px 20px;
  margin-bottom: 32px !important;
  background-color: #F5F5F5;
}

.privacy-policy-section p {
  color: #37474F;
  padding: 0 20px;
  font-size: 16px;
  margin-bottom: 32px;
  line-height: 28px;
}/*# sourceMappingURL=privacy-policy.css.map */