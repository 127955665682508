.front {
  cursor: pointer;
  border: 1px solid #eee;
}

.back {
  cursor: default;
}

/* -----------------------------------------
Profile Page
----------------------------------------- */
:root {
  --cubeWidth: 120px;
}
@media (min-width: 360px) {
  :root {
    --cubeWidth: 142px;
  }
}
@media (min-width: 375px) {
  :root {
    --cubeWidth: 149px;
  }
}
@media (min-width: 390px) {
  :root {
    --cubeWidth: 157px;
  }
}
@media (min-width: 412px) {
  :root {
    --cubeWidth: 168px;
  }
}
@media (min-width: 428px) {
  :root {
    --cubeWidth: 176px;
  }
}
@media (min-width: 768px) {
  :root {
    --cubeWidth: 210px;
  }
}
@media (min-width: 820px) {
  :root {
    --cubeWidth: 227px;
  }
}
@media (min-width: 912px) {
  :root {
    --cubeWidth: 282px;
  }
}
@media (min-width: 1024px) {
  :root {
    --cubeWidth: 300px;
  }
}
@media (min-width: 1366px) {
  :root {
    --cubeWidth: 329px;
  }
}
@media (min-width: 1440px) {
  :root {
    --cubeWidth: 354px;
  }
}
@media (min-width: 1536px) {
  :root {
    --cubeWidth: 342px;
  }
}

.profile-info {
  position: relative;
  width: var(--cubeWidth);
  margin: 0 11px;
}
@media (min-width: 768px) {
  .profile-info {
    margin: 0 15px;
    width: var(--cubeWidth);
  }
}

.profile-info h1 {
  margin: 0;
}

.profile-info ul {
  list-style: none;
  padding: 0;
  margin: 5px auto 20px;
}

.profile-info .profile-title button {
  font-size: 14px;
  font-weight: 700;
  color: #1b1464;
  text-align: left;
  padding: 0;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .profile-info .profile-title button {
    font-size: 18px;
  }
}

.profile-info .profile-subtitle {
  font-weight: bold;
}

.profile-info .profile-misc-title {
  font-style: italic;
}

.profile-info .profile-logo-or-photo {
  display: block;
  width: 100%;
  height: auto;
}

.cube-container {
  position: relative;
  z-index: 1;
}

.box {
  margin: 5px auto;
  position: relative;
  width: var(--cubeWidth);
  height: var(--cubeWidth);
  transform-style: preserve-3d;
  pointer-events: none;
}

.box > div {
  background: #414042;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 22px;
  line-height: 1.4;
  color: #fff;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-origin: 50% 50% calc(var(--cubeWidth) / 2 * -1);
  width: var(--cubeWidth);
  height: var(--cubeWidth);
  transform: perspective(500px);
  pointer-events: none;
}

.box div.back {
  background: #37474f;
}

.box > div.front > button {
  width: 100%;
}

.box > div button {
  text-align: left;
  pointer-events: initial;
  padding: 0;
  font-size: 10px;
}
@media (min-width: 768px) {
  .box > div button {
    font-size: 14px;
  }
}

.quote-text {
  font-size: min(2.7777777778vw, 12px);
  padding: 10px 15px;
}
@media (min-width: 768px) {
  .quote-text {
    font-size: 22px;
    padding: 20px 25px;
  }
}
.quote-text span {
  display: block;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .quote-text span {
    margin-bottom: 16px;
  }
}

.quote-text-only {
  font-size: 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  white-space: nowrap;
  width: 100%;
  margin-top: -12px;
}

.quote-text a,
.quote-text-only a {
  color: #03dafb;
}

.quote-text a:hover,
.quote-text-only a:hover {
  color: #66ffff;
}

.modal-this-content {
  display: none;
}

#filter-box {
  background: #ccc;
  padding: 5px 10px;
}

.filter-text {
  display: inline-block;
  padding: 2px 0;
  font-size: 10px;
  margin: 0 10px;
}

.filter-text:first-child {
  margin-left: 0;
}

.profile-block {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  /*overflow:hidden;*/
  color: #414042;
}

.profile-block:hover a {
  text-decoration: none;
}

.profile-block.profile-block-center {
  text-align: center;
}

.profile-block.profile-vertical-middle {
  vertical-align: middle;
}

.profile-block .profile-padding > .row {
  margin: 0;
}

.profile-block .profile-padding > .row > .col-md-12 {
  padding: 0;
}

.profile-block ul li {
  line-height: 1.4;
}

.profile-category-section h2 {
  font-size: 38px;
  color: #414042;
}

.skillsets-used {
  margin-top: 32px;
  background-color: #1b1464;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
  border-radius: 5px;
}
.skillsets-used::before {
  font-weight: bold;
  content: "Skills applied: ";
}

.visual-gallery {
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .visual-gallery {
    flex-direction: row;
  }
}
.visual-gallery img {
  display: block;
  max-width: 100%;
  width: 100%;
}
@media (min-width: 768px) {
  .visual-gallery .gallery-images {
    width: 60%;
  }
}
.visual-gallery .gallery-description {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  padding: 12px;
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;
}
@media (min-width: 768px) {
  .visual-gallery .gallery-description {
    border-left: 1px solid #eee;
    font-size: 16px;
    width: 40%;
    padding: 20px;
  }
}/*# sourceMappingURL=portfolio.css.map */