#resume{
  box-shadow: 0 0 15px rgba(0, 0, 0, .10);
  padding: 32px;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto 32px;
}

.resume-content{
  flex-direction: column-reverse;
  @media(min-width: 768px){
    flex-direction: row;
  }
}

.download-resume-btn{
  display: flex;
  margin: 0 auto 32px !important;
  box-sizing: border-box;
  font-family: "Bebas Neue", cursive !important;
  font-weight: normal !important;

  font-size: calc(var(--viewportVW) * 24) !important;
  width: calc(var(--viewportVW) * 280);
  height: calc(var(--viewportVW) * 48);
  @media (min-width: 768px) {
    font-size: min(calc(var(--viewportVW) * 48),48px) !important;
    width: min(calc(var(--viewportVW) * 438), 438px);
    height: min(calc(var(--viewportVW) * 96), 96px);
  }
}


.avatar-and-title{
  margin: 0 auto 16px !important;
  @media (min-width: 768px) {
    margin: 0 auto !important;
  }
}
.name-and-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.name-and-title h2{
  font-size: calc(var(--viewportVW) * 32);
  @media (min-width: 768px) {
    font-size: min(calc(var(--viewportVW) * 48), 48px);
  }
}

.name-and-title p{
  font-size: calc(var(--viewportVW) * 16);
  @media (min-width: 768px) {
    font-size: min(calc(var(--viewportVW) * 20), 20px);
  }
}

.contact-info{
  font-size: 14px;
  text-align: left;

  a{
    color: #0A66C2;
    text-decoration-color: #0A66C2;
  }
  @media (min-width: 768px) {
    text-align: right;
  }
}

.button-wrapper{
  text-align: center;
}

@media print {
  @page {
    margin: 0;
  }
  .page-container{
    margin: 0!important;
    padding: 0!important  ;
  }
  .title-bar{
    display: none!important;
    min-height: unset!important;
    margin: 0!important;
    padding: 0!important;
  }
  .button-wrapper{
    display: none!important;
    margin: 0!important;
    padding: 0!important;
  }
  .contact-info{
    text-align: right;
  }
  .name-and-title h2{
    font-size: 48px;
  }
  .name-and-title p{
      font-size: 20px;
  }
  nav{
    display: none;
  }
  main{
    padding: 0!important;
    margin: 0!important;
  }
  main h1{
    display: none;
  }
  .download-resume-btn{
    display: none!important;
  }
  #resume{
    zoom: 50%;
    padding: 20px 25px 0;
    display: block;
    box-shadow: none;
    margin: 0;
  }
}