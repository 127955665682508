.icon-button {
  color: #fff;
}

.menu-links a:hover {
  transition: color 0.25s;
  color: #0A66C2;
}
.menu-links a:hover svg {
  transition: color 0.25s;
  color: #0A66C2;
}
.menu-links a.active {
  color: #1b1464;
}
.menu-links a.active span {
  font-weight: bold;
}
.menu-links a.active svg {
  transition: color 0.25s;
  color: #1b1464;
}

.nav-social-links a {
  margin: 0 8px;
  transition: color 0.25s;
}
.nav-social-links a:hover {
  color: #24c0f0;
}/*# sourceMappingURL=menu.css.map */