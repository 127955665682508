.about-section {
  position: relative;
  background: #ECEFF1;
  margin-bottom: 64px;
  padding-bottom: 32px;
  border-bottom: 5px solid #1B1464;
}

.about-section-title {
  padding: 30px 20px 20px;
  margin-bottom: 32px !important;
  background-color: #F5F5F5;
}

.about-top-border {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.about-section p {
  color: #37474F;
  padding: 0 20px;
  font-size: 16px;
  margin-bottom: 32px;
  line-height: 28px;
}

/* created by https://codepen.io/t_afif/pen/qBJyXNy */
img.parallax {
  position: absolute;
  top: -10px;
  right: 0;
  width: 26.6666666667vw; /* the image size */
  --f: .1; /* the parallax factor (the smaller the better) */
  --r: 10px; /* radius */
  --_f: calc(100%*var(--f)/(1 + var(--f)));
  --_a: calc(90deg*var(--f));
  aspect-ratio: calc(1 + var(--f));
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-clip-path: inset(0 var(--_f) 0 0 round var(--r));
          clip-path: inset(0 var(--_f) 0 0 round var(--r));
  transform: perspective(400px) var(--_t, rotateY(calc(-1 * var(--_a))));
  transition: 0.5s;
  cursor: pointer;
}
@media (min-width: 600px) {
  img.parallax {
    top: -24px;
    width: min(25vw, 150px); /* the image size */
  }
}

img.parallax:hover {
  -webkit-clip-path: inset(0 0 0 var(--_f) round var(--r));
          clip-path: inset(0 0 0 var(--_f) round var(--r));
  --_t:translateX(calc(-1*var(--_f))) rotateY(var(--_a)) ;
}/*# sourceMappingURL=about.css.map */